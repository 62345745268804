<script setup lang="ts">
  import useCpBem from '~/composables/useCpBem';
  import CpText from '~/components/cp/CpText/CpText.vue';

  defineProps({
    fancy: {
      type: Boolean,
      required: false,
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },
  });

  const {bm, e} = useCpBem('cp-divider');
</script>

<template>
    <div v-if="!fancy" :class="bm({'vertical': vertical!})" />
    <div v-else :class="e('fancy')">
        <div :class="e('fancy-line-l')" />
        <CpText variant="subheading" type="neutral">
            o
        </CpText>
        <div :class="e('fancy-line-r')" />
    </div>
</template>

<style scoped lang="scss">
  .cp-divider {
    flex: 1;
    border-top: 1px solid var(--color-divider);

    &--vertical {
        border-top: none;
        border-left: 1px solid var(--color-divider);
        height: 100%;
    }

    &__fancy {
      display: flex;
      align-items: center;
    }

    &__fancy-line-l {
      flex: 1;
      border-top: 1px solid var(--color-neutral-extra-light);
      margin-right: 8px;
    }
    &__fancy-line-r {
      flex: 1;
      border-top: 1px solid var(--color-neutral-extra-light);
      margin-left: 8px;
    }
  }
</style>
